import * as Types from './types';

const INITIAL_STATE = {
  token: '',
};

export default (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case Types.SET_TOKEN:
      return {
        token: payload.token,
      };

    case Types.LOGOUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};
