import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 10%;

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    margin-top: 30px;
    width: 100%;
  }

  td, th {
    border: 1px solid #fff;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #fff;
  }
`;
