import styled from 'styled-components';

export const SearchInput = styled.div`
  margin: 3% 0 0 10%;

  > div{
      background: #fff;
      padding: 5px;
      width: 256px;
      border-radius: 40px;

    > input {
      background: #fff;
      border: 0;
      color: #c4c4c4;
      padding-left: 4px;
      outline: none;
    }

    > svg {
      cursor: pointer;
    }
  }
`;

export const Container = styled.div`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    margin: 3% 0 0 10%;
    width: 75%;
  }

  table .edit {
    cursor: pointer;
  }

  table .edit:hover {
    > svg {
      color: blue;
    }
  }

  table .delete {
    cursor: pointer;
  }

  table .delete:hover {
    > svg {
      color: red;
    }
  }

  td, th {
    border: 1px solid #fff;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #fff;
  }

  .pagination {
    margin: 2% 0px 0px 10%;
  }
`;

export const Form = styled.form`
  .wordRegister {
    > input {
      border-radius: 10px;
      border: 0;
      width: 415px;
      padding-left: 36px;
      height: 36px;
      background: #f0f2f5;
      margin-bottom: 10px;

      ::placeholder {
        color: red;
        opacity: 1;
      }

      :-ms-input-placeholder {
      color: red;
      }

      ::-ms-input-placeholder {
      color: red;
      }
    }

    > svg, span {
      display: table;
      margin: 0 auto;
    }

    > span {
      color: red;
    }
  }
`;
