import { useEffect, useState } from 'react';
import { FaTrash, FaEdit, FaExternalLinkAlt, FaSpinner } from 'react-icons/fa';
import api from '../../../services/api';
import { Modal as ModalBootstrap } from 'react-bootstrap';
import { ImageUpload } from './ImageUpload';

import { ActionBtn, ActionBtnAdd, ActionBtnEditDel } from './_TableTr';

interface TableTrProps {
  word: any;
  handleModalEdit: (word: any) => void;
  modalDelete: (wordId: string) => void;
  handleChangePage: (e: any) => void;
  page: number;
}

interface WordImageInterfa {
  name: string;
  url: string;
}

export function TableTr({
  word,
  handleModalEdit,
  modalDelete,
  handleChangePage,
  page,
}: TableTrProps) {
  const [wordImageData, setWordImageData] = useState<WordImageInterfa[]>([]);
  const [wordImage, setWordImage] = useState('');
  const [wordId, setWordId] = useState('');
  const [wordImageId, setWordImageId] = useState('');
  const [showImage, setShowImage] = useState(false);
  const [showImageAdd, setShowImageAdd] = useState(false);
  const [type, setType] = useState('');
  const [loading, setLoading] = useState(false);

  const { User } = JSON.parse(sessionStorage.getItem('libra@User') || '{}');

  useEffect(() => {
    setLoading(true);
    async function getWordImages() {
      const headers = { Authorization: `Bearer ${User.token}` };
      const response = await api.get(`/wordimage?word_id=${word.id}`, {
        headers,
      });

      setWordImageData(response.data.results);
      setLoading(false);
    }

    getWordImages();
  }, [word]);

  async function openImage(wordId: string) {
    try {
      const headers = { Authorization: `Bearer ${User.token}` };
      const response = await api.get(`wordimage?word_id=${wordId}`, {
        headers,
      });

      setWordImage(response.data.results[0].url);
      setWordId(response.data.results[0].word_id);
      setWordImageId(response.data.results[0].id);
      setShowImage(true);
    } catch (error) {
      console.error('Ops! ocorreu um erro', error.response.data.message);
    }
  }

  async function handleAddImage(wordId: string, type: string) {
    setType(type);
    setWordId(wordId);
    setShowImageAdd(true);
  }

  function renderOpenImage(wordId: string) {
    if (loading) {
      return (
        <FaSpinner
          style={{ display: 'table', margin: '10px auto' }}
          size={30}
          className="icon-spin"
        />
      );
    } else if (wordImageData.length > 0) {
      return (
        <td>
          <ActionBtn
            onClick={() => openImage(wordId)}
            style={{ cursor: 'pointer' }}
          >
            {' '}
            <FaExternalLinkAlt /> Abrir imagem
          </ActionBtn>
          &nbsp;&nbsp;&nbsp;
          <ActionBtn
            onClick={() => handleAddImage(wordImageId, 'edit')}
            style={{ cursor: 'pointer' }}
          >
            <FaEdit /> Editar imagem
          </ActionBtn>
        </td>
      );
    } else {
      return (
        <td>
          <ActionBtnAdd
            onClick={() => handleAddImage(wordId, 'add')}
            style={{ cursor: 'pointer' }}
          >
            <FaExternalLinkAlt /> Adicionar imagem{' '}
          </ActionBtnAdd>
        </td>
      );
    }
  }

  return (
    <>
      <tr key={word.id}>
        <td>{word.name}</td>
        <td>{word.meaning}</td>
        <td>
          <ActionBtnEditDel
            className="edit"
            onClick={() => handleModalEdit(word)}
          >
            <FaEdit /> Editar
          </ActionBtnEditDel>
          &nbsp;&nbsp;&nbsp;
          <ActionBtnEditDel
            className="delete"
            onClick={() => modalDelete(word.id)}
          >
            {' '}
            <FaTrash /> Deletar
          </ActionBtnEditDel>
        </td>
        {renderOpenImage(word.id)}
      </tr>
      <ModalBootstrap show={showImage} onHide={() => setShowImage(false)}>
        <img src={wordImage} />
      </ModalBootstrap>
      <ModalBootstrap show={showImageAdd} onHide={() => setShowImageAdd(false)}>
        <ImageUpload
          wordId={wordId}
          wordImageId={wordImageId}
          handleChangePage={handleChangePage}
          page={page}
          type={type}
          setShowImageAdd={setShowImageAdd}
        />
      </ModalBootstrap>
    </>
  );
}
