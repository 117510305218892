import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-left: 10%;

  div:nth-child(1) {
    border-radius: 10px;
    background: #fff;
    width: 23%;
    padding: 30px;
    margin-top: 30px;
    height: 181px;
    opacity: 0.3;
    cursor: no-drop;

    > div {
      display: flex;
      justify-content: space-between;

      > p {
        display: flex;
        align-items: baseline;

      }

      span {
        border-radius: 50px;
        background: #00c5dc;
        height: 11px;
        width: 11px;
        display: block;
        color: #fff;
        display: flex;
        align-items: center;
        margin-right: 5px;
      }
    }
  }

  div:nth-child(2) {
    border-radius: 10px;
    background: #fff;
    width: 23%;
    padding: 30px;
    margin-top: 30px;
    margin-left: 80px;
    opacity: 0.3;
    cursor: no-drop;

    > p {
      color: #1b2da1;
      text-align: center;
    }

    > ul {
      display: flex;
      justify-content: space-between;
      list-style: none;

      > li {
        > span {
          display: block;
          height: 156px;
          background: #ff621c;
          width: 8px;
          border-radius: 10px;
          margin: 10px auto;

          > span {
            display: block;
            height: 90px;
            background: #c7c7c8;
            width: 8px;
            border-radius: 10px;
            margin: 10px auto;
          }
        }
      }
    }
  }

`;
