import styled from 'styled-components';

export const Container = styled.div`
  opacity: 0.5;
  cursor: no-drop;
  margin: 3% 0 0 10%;

  > div {
    display: flex;
    justify-content: space-around;
  }

  h6 {
    color: #4856b3;
  }

  input {
    border-radius: 5px;
    border: 1px solid lightgrey;
    background: #fff;
    padding: 3px 0 3px 10px;
    cursor: no-drop;
  }

  select {
    border: 2px solid #0fc8de;
    border-radius: 3px;
    color: #0fc8de;
    padding: 3px 50px 3px 10px;
    cursor: no-drop;
  }
`;
