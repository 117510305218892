import { useEffect, useState, FormEvent } from 'react';
import Navbar from '../../components/VerticalMenu/Navbar';
import api from '../../services/api';
import { FaEdit, FaTrash, FaSpinner, FaSearch } from 'react-icons/fa';
import Modal from 'react-modal';
import { Modal as ModalBootstrap } from 'react-bootstrap';
import Button from '../../components/Button';
import { Pagination } from 'antd';
import { WordRegister } from '../WordRegister/WordRegister';
import { TableTr } from './Components/TableTr';
import { toast } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';

import { Container, Form, SearchInput } from './styles';
import { SearchWordsImages } from '../SearchWordsImages/SearchWordsImages';

interface UsersDataInterface {
  id: string;
  name: string;
  meaning: string;
}

export const SearchWords = () => {
  const [wordData, setWordData] = useState<UsersDataInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [wordId, setWordId] = useState('');
  const [name, setName] = useState('');
  const [meaning, setMeaning] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(5);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageReg, setErrorMessageReg] = useState('');
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      height: '320px',
      width: '500px',
      borderRadius: '30px',
      boxShadow: '0px 0px 6px 0px lightgrey',
      border: 0,
    },
  };

  const { User } = JSON.parse(sessionStorage.getItem('libra@User') || '{}');

  useEffect(() => {
    async function getUSers() {
      const headers = { Authorization: `Bearer ${User.token}` };
      const response = await api.get('/word?name=&limit=10', { headers });

      setWordData(response.data.results);
      setPage(parseInt(response.data.page));
      setTotal(response.data.total);
      setLoading(true);
    }

    getUSers();
  }, [reloadTable]);

  async function handleSearch() {
    const headers = { Authorization: `Bearer ${User.token}` };

    const response = await api.get(`/word?name=${search}`, { headers });

    setWordData(response.data.results);
    setLoading(true);
  }

  function handleChangePage(e: any) {
    setPage(parseInt(e));
    const headers = { Authorization: `Bearer ${User.token}` };
    api
      .get(`word?name=${search}&page=${e}&limit=10`, { headers })
      .then((response) => {
        setWordData(response.data.results);
      });
  }

  async function handleSubmit(
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault();

    if (!name || !meaning) {
      setErrorMessageReg('Preencha este campo');
    } else {
      setShowLoadModal(true);
      setErrorMessage('');
      setErrorMessageReg('');

      const usr = {
        name,
        meaning,
      };
      try {
        const headers = { Authorization: `Bearer ${User.token}` };
        await api.put(`word/${wordId}`, usr, { headers });

        toast.success('Edição realizada com sucesso!');
        setOpenModal(false);
        handleChangePage(page);
      } catch (error) {
        console.error('Ops! ocorreu um erro', error.response.data.message);
        setErrorMessage(error.response.data.message);
      }

      setShowLoadModal(false);
    }
  }

  function handleModalEdit(word: UsersDataInterface) {
    setName(word.name);
    setMeaning(word.meaning);
    setWordId(word.id);

    setOpenModal(true);
  }

  async function handleModalDelete() {
    try {
      const headers = { Authorization: `Bearer ${User.token}` };
      await api.delete(`word/${wordId}`, { headers });

      toast.success('Palavra removida!');
      setShowModalDelete(false);
      handleChangePage(page);
    } catch (error) {
      console.error('Ops! ocorreu um erro', error.response.data.message);
      setErrorMessage(error.response.data.message);
    }
  }

  function modalDelete(wordId: string) {
    setWordId(wordId);

    setShowModalDelete(true);
  }

  return (
    <>
      <Navbar />
      <WordRegister reloadTable={reloadTable} setReloadTable={setReloadTable} />
      <SearchInput>
        <div>
          <input
            placeholder="Pesquisar..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <FaSearch onClick={() => handleSearch()} color="#1b2da1" />
        </div>
      </SearchInput>
      <Container>
        <table>
          <tr>
            <th>Palavra</th>
            <th>Significado</th>
            <th>Ações</th>
            <th>Imagem</th>
          </tr>
          {loading ? (
            wordData.map((word) => (
              <TableTr
                word={word}
                handleModalEdit={handleModalEdit}
                modalDelete={modalDelete}
                handleChangePage={handleChangePage}
                page={page}
              />
            ))
          ) : (
            <div style={{ display: 'table', margin: '40px auto' }}>
              <LoadingOutlined />
            </div>
          )}
        </table>
        <div className="pagination">
          <Pagination
            defaultCurrent={page}
            total={total}
            onChange={(e) => handleChangePage(e)}
          />
        </div>
        <ModalBootstrap
          show={showModalDelete}
          onHide={() => setShowModalDelete(false)}
        >
          <div style={{ padding: 20, borderRadius: 50, textAlign: 'center' }}>
            <h5>Relamente deseja deletar essa palavra?</h5>
            <Button
              containerStyle={{ margin: '4vh 0 1vh 0' }}
              text="Sim"
              type="submit"
              handleClick={() => handleModalDelete()}
            />
            <Button
              containerStyle={{ margin: '4vh 0 1vh 0', background: 'red' }}
              text="Não"
              type="submit"
              handleClick={() => setShowModalDelete(false)}
            />
          </div>
        </ModalBootstrap>
        <Modal
          isOpen={openModal}
          onRequestClose={() => setOpenModal(false)}
          style={customStyles}
        >
          <Form onSubmit={handleSubmit}>
            <div className="wordRegister">
              <h5>Palavra</h5>
              <input
                placeholder={errorMessageReg}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <h5>Significado</h5>
              <input
                placeholder={errorMessageReg}
                value={meaning}
                onChange={(e) => setMeaning(e.target.value)}
              />
              <Button
                containerStyle={{ margin: '4vh 0 1vh 0' }}
                text="Salvar"
                type="submit"
              />
              {showLoadModal ? (
                <FaSpinner size={30} className="icon-spin" />
              ) : (
                ''
              )}
              {errorMessage && <span>{errorMessage}</span>}
            </div>
          </Form>
        </Modal>
      </Container>
    </>
  );
};
