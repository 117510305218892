import styled, { createGlobalStyle } from "styled-components";

import 'react-toastify/dist/ReactToastify.css';

export const GlobalStyle = createGlobalStyle`
    :root {
        --primary: #1b2da1;
        --secondary: #00c5dc;
        --background: #F0F2F5;
    }
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    html {
        @media (max-width: 1080px) {
            font-size: 93.75%;
        }
        @media (max-width: 720px) {
            font-size: 87.5%;
        }
    }
    body {
        background: var(--background);
        -webkit-font-smoothing: antialiased;
    }
    body, input, textarea, button, td, tr {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }
    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 600;
    }
    button {
        cursor: pointer;
    }
    [disabled] {
        opacity: 0;
        cursor: not-allowed;
    }
`;
