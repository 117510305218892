import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  height: 64px;
  background-color: #f6f6f6;
  border-radius: 10px;
  margin: 0px 0px;
  padding: 0px 20px;
  padding-right: 48px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
`;

export const Image = styled.img`
  height: 30px;
`;

export const TextInput = styled.input`
  font-size: 1rem;
  border-style: none;
  background-color: #f6f6f6;
  margin-left: 20px;
  outline: none;
  width: 100%;
  color: #c5c5c5;
  &::placeholder {
    color: #c5c5c5;
  }
`;

export const IconRight = styled.img`
  position: absolute;
  height: 30px;
  right: 10px;
`;
