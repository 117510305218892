import Navbar from '../../components/VerticalMenu/Navbar';

import { Container } from './styles';

export const Dashboard = () => {
  return (
    <>
    <Navbar/>
      <h4 style={{margin: '2% 0 0 10%'}}>Dashboard</h4>
      <Container>

        <div>
          <h5>Faturamento mensal</h5>
          <h3>R$ 5.000</h3>

          <h6>Vendas realizadas</h6>

          <div>
            <p><span/>730</p>
            <p>Jun</p>
          </div>
        </div>

        <div>
          <p>Geral</p>

          <ul>
            <li>
              <span style={{background: '#00c5dc'}}>
              <span style={{height: '60px'}}/>
              </span>
              Jan
            </li>
            <li>
              <span>
              <span style={{height: '30px'}}/>
              </span>
              Fev
            </li>
            <li>
              <span style={{background: '#00c5dc'}}>
              <span style={{height: '20px'}}/>
              </span>
              Mar
            </li>
            <li>
              <span>
                <span/>
              </span>
              Abr
            </li>
            <li>
              <span style={{background: '#00c5dc'}}>
                <span style={{height: '40px'}}/>
              </span>
              Jun
            </li>
          </ul>
        </div>
      </Container>
    </>
  );
};
