import { useState, useEffect, FormEvent } from 'react';
import { toast } from 'react-toastify';
import { FaPlus, FaSpinner } from 'react-icons/fa';
import Button from '../../components/Button';

import api from '../../services/api';

import { Container, Form } from './styles';

interface WordDataInterface {
  id: string;
  name: string;
  meaning: string;
}

interface WordRegisterProps {
  reloadTable: any;
  setReloadTable: any;
}

export const WordRegister = ({
  reloadTable,
  setReloadTable,
}: WordRegisterProps) => {
  const [word, setWord] = useState<WordDataInterface[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageReg, setErrorMessageReg] = useState('');
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [name, setName] = useState('');
  const [meaning, setMeaning] = useState('');

  const { UserInfo, User } = JSON.parse(
    sessionStorage.getItem('libra@User') || '{}',
  );

  useEffect(() => {
    async function getUSers() {
      const headers = { Authorization: `Bearer ${User.token}` };
      const response = await api.get('/word', { headers });

      setWord(response.data.results);
    }

    getUSers();
  }, [showLoadModal]);

  async function handleSubmiWord(
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault();

    if (!name || !meaning) {
      setErrorMessageReg('Preencha este campo*');
    } else {
      setShowLoadModal(true);
      setErrorMessage('');
      setErrorMessageReg('');

      const usr = {
        name,
        meaning,
      };
      try {
        const headers = { Authorization: `Bearer ${User.token}` };
        await api.post('word', usr, { headers });

        toast.success('Cadastro realizado!');
        setName('');
        setMeaning('');
        setReloadTable(!reloadTable);
      } catch (error) {
        console.error('Ops! ocorreu um erro', error.response.data.message);
        setErrorMessage(error.response.data.message);
      }

      setShowLoadModal(false);
    }
  }

  return (
    <>
      <Container>
        <div>
          <Form onSubmit={handleSubmiWord}>
            <div className="wordRegister">
              <h4 style={{ textAlign: 'center' }}>Cadastro de palavras</h4>

              <h5>Palavra</h5>
              <input
                placeholder={errorMessageReg}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <h5>Significado</h5>
              <input
                placeholder={errorMessageReg}
                value={meaning}
                onChange={(e) => setMeaning(e.target.value)}
              />
              <Button
                containerStyle={{ marginTop: '4vh' }}
                text="Cadastrar palavra"
                type="submit"
              />
              {showLoadModal ? (
                <FaSpinner size={30} className="icon-spin" />
              ) : (
                ''
              )}
              {errorMessage && <span>{errorMessage}</span>}
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};
