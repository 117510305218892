import React, { useState, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import Button from '../../components/Button';
import Input from '../../components/Input';

import api from '../../services/api';

import { Container, Form, Row, LinkPage } from './styles';

import './styles.css';

export const Login: React.FC = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoadModal, setShowLoadModal] = useState(false);
  const history = useHistory();

  async function handleSubmit(
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault();

    if (!email || !password) {
      setErrorMessage('Preencha e-mail e senha para continuar!');
    } else {
      setShowLoadModal(true);
      setErrorMessage('');

      const usr = {
        email,
        password,
      };
      try {
        const response = await api.post('/session', usr);
        dispatch({
          type: 'libras-adm/user/set-token',
          payload: response.data,
        });

        dispatch({
          type: 'libras-adm/user/user-info',
          payload: response.data,
        });

        history.push('/dashboard');
      } catch (error) {
        console.error('Ops! ocorreu um erro', error.response.data.message);
        setErrorMessage(error.response.data.message);
      }

      setShowLoadModal(false);
    }
  }

  return (
    <Container>
      <Form hasError={!!errorMessage} onSubmit={handleSubmit}>
        <h1>Libras ADM</h1>
        <Input
          containerStyle={{ marginTop: '4vh' }}
          alt="User Icon"
          placeholder="E-mail...."
          type="text"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Input
          containerStyle={{ marginTop: '1.2vh' }}
          alt="Lock Icon"
          placeholder="Senha...."
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <Button
          containerStyle={{ marginTop: '4vh' }}
          text="Entrar"
          type="submit"
        />
        {showLoadModal ? <FaSpinner size={30} className="icon-spin" /> : ''}
        {errorMessage && <span>{errorMessage}</span>}
      </Form>
    </Container>
  );
};
