import styled from 'styled-components';

export const Container = styled.div`
  margin: 3% 0 0 10%;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    .wordRegister {
      display: flex;
      flex-direction: column;
      margin-right: 151px;

      background: #fff;
      padding: 20px;
      border-radius: 10px;

    > h5 {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    > input {
      border-radius: 10px;
      border: 0;
      width: 415px;
      padding-left: 36px;
      height: 36px;
      background: #f0f2f5;

      ::placeholder {
        color: red;
        opacity: 1;
      }

      :-ms-input-placeholder {
      color: red;
      }

      ::-ms-input-placeholder {
      color: red;
      }
    }

    .registerBtn {
      border: 0;
      background: #00c5dc;
      color: #fff;
      padding: 10px 40px 10px 40px;
      border-radius: 10px;
      margin-top: 10px;
    }

    > svg, span {
      align-self: center;
      margin-top: 10px;
    }

    > span {
      color: red;
    }
  }

  .imgRegister {
    border-radius: 10px;
    width: 400px;

  > h5 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

    .imgRegister {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #fff;
      padding: 65px;
      margin-bottom: 10px;

      > div {
        position: relative;

        > img {
        border-radius: 10px;
      }
    }

  label {
    cursor: pointer;
    margin: 0;
    background: #00c5dc;
    position: absolute;
    top: 110px;
    right: -13px;
    cursor: pointer;
    border-radius: 50px;
    padding: 10px 14px 10px 14px;

    &:hover {
      opacity: 0.7;
    }
    input {
      display: none;
    }
  }
}

  .wordSelect {
    display: flex;
    flex-direction: column;

    > span {
      color: red;
      text-align: center;
      margin: 5px 0 5px 0;
    }
  }

  .registerBtn {
    font-size: 1.25rem;
    border: 0;
    background: #00c5dc;
    color: #fff;
    padding: 10px 40px 10px 40px;
    border-radius: 10px;
    width: 100%;
    margin-top: 10px;
    }
  }
}

@media (max-width: 1077px) {
    .wordRegister {
      margin-right: 65px;
    }
  }

@media (max-width: 984px) {
    margin: 3% 0 0 3%;

    > div {
      display: flex;
      flex-direction: column;

      .wordRegister {
        margin: 0;

        > input {
          width: 100%;
        }
      }

      .imgRegister {
        width: 100%;
      }
    }
  }
`;

export const Form = styled.form`

`;
