import { useEffect, useState, FormEvent } from 'react';
import styled from 'styled-components';
import defaultImg from '../../../assets/defaultImg.png';
import { FaPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';

import api from '../../../services/api';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .imgRegister {
    border-radius: 10px;
    width: 400px;

    > h5 {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .imgRegister {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #fff;
      padding: 65px;
      margin-bottom: 10px;

      > div {
        position: relative;

        > img {
          border-radius: 10px;
        }
      }

      label {
        cursor: pointer;
        margin: 0;
        background: #00c5dc;
        position: absolute;
        top: 110px;
        right: -13px;
        cursor: pointer;
        border-radius: 50px;
        padding: 10px 14px 10px 14px;

        &:hover {
          opacity: 0.7;
        }
        input {
          display: none;
        }
      }
    }

    .registerBtn {
      font-size: 1.25rem;
      border: 0;
      background: #00c5dc;
      color: #fff;
      padding: 10px 40px 10px 40px;
      border-radius: 10px;
      width: 100%;
      margin-top: 10px;
    }
  }

  .errorMessage {
    color: red;
    display: table;
    margin: 0 auto;
  }
`;

interface WordDataInterface {
  id: string;
  name: string;
  meaning: string;
}

interface ImageUploadProps {
  wordId: string;
  wordImageId: string;
  type: string;
  handleChangePage: (e: any) => void;
  page: number;
  setShowImageAdd: (e: any) => void;
}

export function ImageUpload({
  wordId,
  wordImageId,
  type,
  handleChangePage,
  page,
  setShowImageAdd,
}: ImageUploadProps) {
  const [fileSelected, setFileSelected] = useState<File>();
  const [wordImg, setWordImg] = useState();
  const [word, setWord] = useState<WordDataInterface[]>([]);
  const [errorMessageImg, setErrorMessageImg] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageReg, setErrorMessageReg] = useState('');
  const [loading, setLoading] = useState(false);

  const { UserInfo, User } = JSON.parse(
    sessionStorage.getItem('libra@User') || '{}',
  );

  const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;

    if (!fileList) return;

    setFileSelected(fileList[0]);
  };

  const uploadFile = async function (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) {
    if (!fileSelected) {
      setErrorMessageImg('Selecione uma imagem');
      return;
    }
    setLoading(true);
    if (fileSelected) {
      const formData = new FormData();
      formData.append('file', fileSelected, fileSelected.name);

      if (type === 'add') {
        try {
          const response = await api.post(`wordimage/${wordId}`, formData, {
            headers: {
              'Content-Type': `multipart/form-data;`,
              Authorization: `Bearer ${User.token}`,
            },
          });

          setWordImg(response.data.avatar_url);
          toast.success('Imagem cadastrada!');
          setErrorMessageImg('');
          setErrorMessage('');
          setErrorMessageReg('');
          setFileSelected(undefined);
          handleChangePage(page);
          setLoading(false);
          setShowImageAdd(false);
        } catch (error) {
          console.error('Ops! ocorreu um erro', error.response.data.message);
          setErrorMessageImg(error.response.data.message);
          toast.error('Não foi possível carregar a imagem');
          setLoading(false);
        }
      } else {
        try {
          const response = await api.put(`wordimage/${wordImageId}`, formData, {
            headers: {
              'Content-Type': `multipart/form-data;`,
              Authorization: `Bearer ${User.token}`,
            },
          });

          setWordImg(response.data.avatar_url);
          toast.success('Imagem cadastrada!');
          setErrorMessageImg('');
          setErrorMessage('');
          setErrorMessageReg('');
          setFileSelected(undefined);
          handleChangePage(page);
          setLoading(false);
          setShowImageAdd(false);
        } catch (error) {
          console.error('Ops! ocorreu um erro', error.response.data.message);
          setErrorMessageImg(error.response.data.message);
          toast.error('Não foi possível carregar a imagem');
          setLoading(false);
        }
      }
    }
  };

  async function handleSubmitImg(
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault();
  }

  return (
    <>
      <Form onSubmit={handleSubmitImg}>
        <div className="imgRegister">
          <h5>Carregar Imagem</h5>

          <div className="imgRegister">
            <div>
              <img src={defaultImg} height="140px" />
              <label htmlFor="avatar">
                <FaPlus color="#fff" />
                <input
                  id="avatar"
                  accept="image/*"
                  type="file"
                  onChange={handleImageChange}
                />
              </label>
            </div>
            <div style={{ marginTop: '20px' }}>
              {fileSelected && fileSelected.name}
            </div>
          </div>
          <span className="errorMessage">{errorMessageImg}</span>
          <button className="registerBtn" onClick={uploadFile}>
            {loading ? <LoadingOutlined /> : 'Cadastrar imagem'}
          </button>
        </div>
      </Form>
    </>
  );
}
