import { useEffect, useState, FormEvent } from 'react';
import Navbar from '../../components/VerticalMenu/Navbar';
import Input from '../../components/Input';
import api from '../../services/api';
import {
  FaExternalLinkAlt,
  FaEdit,
  FaTrash,
  FaPlus,
  FaSpinner,
} from 'react-icons/fa';
import Modal from 'react-modal';
import { Modal as ModalBootstrap } from 'react-bootstrap';
import Button from '../../components/Button';
import { Pagination } from 'antd';
import defaultImg from '../../assets/defaultImg.png';

import { Container, Form } from './styles';

interface UsersDataInterface {
  id: string;
  name: string;
  url: string;
  word_name: string;
  word_meaning: string;
}

export const SearchWordsImages = () => {
  const [wordImage, setWordImage] = useState<UsersDataInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [image, setImage] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [wordId, setWordId] = useState('');
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageReg, setErrorMessageReg] = useState('');
  const [errorMessageImg, setErrorMessageImg] = useState('');
  const [fileSelected, setFileSelected] = useState<File>();
  const [wordSelectID, setWordSelectId] = useState('');
  const [wordImg, setWordImg] = useState();
  const [showEditModal, setShowEditModal] = useState(false);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      height: '480px',
      width: '690px',
      borderRadius: '30px',
      boxShadow: '0px 0px 6px 0px lightgrey',
      border: 0,
    },
  };

  const { UserInfo, User } = JSON.parse(
    sessionStorage.getItem('libra@User') || '{}',
  );

  useEffect(() => {
    async function getUSers() {
      const headers = { Authorization: `Bearer ${User.token}` };
      const response = await api.get('/wordimage?word_name=&limit=10', {
        headers,
      });

      setWordImage(response.data.results);
      setPage(parseInt(response.data.page));
      setTotal(response.data.total);
      setLoading(true);
    }

    getUSers();
  }, [openModal, showModalDelete]);

  async function handleSearch() {
    const headers = { Authorization: `Bearer ${User.token}` };

    const response = await api.get(`/wordimage?word_name=${search}`, {
      headers,
    });

    setWordImage(response.data.results);
    setLoading(true);
  }

  function handleChangePage(e: any) {
    const headers = { Authorization: `Bearer ${User.token}` };
    api
      .get(`wordimage?word_name=${search}&page=${e}&limit=10`, { headers })
      .then((response) => {
        setWordImage(response.data.results);
      });
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function openImage(wordId: string) {
    setImage(wordId);
    handleShow();
  }

  function handleModalEdit(word: UsersDataInterface) {
    setWordId(word.id);
    setWordSelectId(word.id);
    setOpenModal(true);
  }

  async function handleModalDelete() {
    try {
      const headers = { Authorization: `Bearer ${User.token}` };
      await api.delete(`wordimage/${wordId}`, { headers });

      setShowModalDelete(false);
    } catch (error) {
      console.error('Ops! ocorreu um erro', error.response.data.message);
      setErrorMessage(error.response.data.message);
    }
  }

  function modalDelete(wordId: string) {
    setWordId(wordId);

    setShowModalDelete(true);
  }

  const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;

    if (!fileList) return;

    setFileSelected(fileList[0]);
  };

  const uploadFile = async function (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) {
    if (!wordSelectID) {
      setErrorMessageImg('Selecione uma palavra');
      return;
    }

    if (!fileSelected) {
      setErrorMessageImg('Selecione uma imagem');
      return;
    }

    if (fileSelected) {
      const formData = new FormData();
      formData.append('file', fileSelected, fileSelected.name);

      try {
        const response = await api.put(`wordimage/${wordSelectID}`, formData, {
          headers: {
            'Content-Type': `multipart/form-data;`,
            Authorization: `Bearer ${User.token}`,
          },
        });

        setWordImg(response.data.avatar_url);
        setErrorMessageImg('');
        setErrorMessage('');
        setErrorMessageReg('');
        setFileSelected(undefined);
        setShowEditModal(true);
      } catch (error) {
        console.error('Ops! ocorreu um erro', error.response.data.message);
        setErrorMessageImg(error.response.data.message);
      }
    }
  };

  async function handleSubmitImg(
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    event.preventDefault();
  }

  return (
    <>
      <Navbar />
      <h4 style={{ margin: '2% 0 0 10%' }}>Pesquisa de palavas/imagens</h4>
      <Container>
        <div>
          <Input value={search} onChange={(e) => setSearch(e.target.value)} />
          <Button text="Pesquisar" handleClick={() => handleSearch()} />
        </div>
        <table>
          <tr>
            <th>Palavra</th>
            <th>Imagem</th>
            <th>Ações</th>
          </tr>
          {loading ? (
            wordImage.map((word) => (
              <tr>
                <td>{word.word_name}</td>
                <td
                  onClick={() => openImage(word.url)}
                  style={{ cursor: 'pointer' }}
                >
                  Abrir imagem <FaExternalLinkAlt />
                </td>
                <td>
                  <span className="edit" onClick={() => handleModalEdit(word)}>
                    <FaEdit /> Editar
                  </span>{' '}
                  |
                  <span className="delete" onClick={() => modalDelete(word.id)}>
                    {' '}
                    <FaTrash /> Deletar
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <h5>Carregando...</h5>
          )}
        </table>
        <div className="pagination">
          <Pagination
            defaultCurrent={page}
            total={total}
            onChange={(e) => handleChangePage(e)}
          />
        </div>
        <ModalBootstrap show={showEditModal} onHide={setShowEditModal}>
          <div style={{ padding: 20, borderRadius: 50, textAlign: 'center' }}>
            <h5>Edição realizada com sucesso!</h5>
          </div>
        </ModalBootstrap>
        <ModalBootstrap
          show={showModalDelete}
          onHide={() => setShowModalDelete(false)}
        >
          <div style={{ padding: 20, borderRadius: 50, textAlign: 'center' }}>
            <h5>Relamente deseja deletar essa imagem?</h5>
            <Button
              containerStyle={{ margin: '4vh 0 1vh 0' }}
              text="Sim"
              type="submit"
              handleClick={() => handleModalDelete()}
            />
            <Button
              containerStyle={{ margin: '4vh 0 1vh 0', background: 'red' }}
              text="Não"
              type="submit"
              handleClick={() => setShowModalDelete(false)}
            />
          </div>
        </ModalBootstrap>
        <ModalBootstrap show={show} onHide={handleClose}>
          {loading ? <img src={image} /> : <h5>Carregando...</h5>}
        </ModalBootstrap>
        <Modal
          isOpen={openModal}
          onRequestClose={() => setOpenModal(false)}
          style={customStyles}
        >
          <Form onSubmit={handleSubmitImg}>
            <div className="imgRegister">
              <h5>Carregar Imagem</h5>

              <div className="imgRegister">
                <div>
                  <img src={defaultImg} height="140px" />
                  <label htmlFor="avatar">
                    <FaPlus color="#fff" />
                    <input
                      id="avatar"
                      accept="image/*"
                      type="file"
                      onChange={handleImageChange}
                    />
                  </label>
                </div>
                <div style={{ marginTop: '20px' }}>
                  {fileSelected && fileSelected.name}
                </div>
              </div>
              <button className="registerBtn" onClick={uploadFile}>
                Salvar
              </button>
            </div>
          </Form>
        </Modal>
      </Container>
    </>
  );
};
