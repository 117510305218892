import { HashRouter, Switch } from 'react-router-dom';
import Route from './Route';

import { Login } from '../pages/Login/Login';
import { Dashboard } from '../pages/Dashboard/Dashboard';
//import { WordRegister } from '../pages/WordRegister/WordRegister';
import { Users } from '../pages/Users/Users';
import { ConfigPag } from '../pages/ConfigPag/ConfigPag';
import { SearchWords } from '../pages/SearchWords/SearchWords';
import { SearchWordsImages } from '../pages/SearchWordsImages/SearchWordsImages';
import { NotRegisteredWords } from '../pages/NotRegisteredWords/NotRegisteredWords';

const Routes = () => (
  <HashRouter>
    <Switch>
      <Route path="/" exact component={Login}/>
      <Route path="/dashboard" exact component={Dashboard} isPrivate/>
      {/*<Route path="/word-register" exact component={WordRegister} isPrivate/>*/}
      <Route path="/usuarios" exact component={Users} isPrivate/>
      <Route path="/config" exact component={ConfigPag} isPrivate/>
      <Route path="/palavras" exact component={SearchWords} isPrivate/>
      <Route path="/palavras-nao-encontradas" exact component={NotRegisteredWords} isPrivate/>
      <Route path="/palavras-imagens" exact component={SearchWordsImages} isPrivate/>
    </Switch>
  </HashRouter>
);

export default Routes;
