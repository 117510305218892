
import  { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as FaIcons from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import './styles.css';
import { IconContext } from 'react-icons';

function Navbar() {
  const [sidebar, setSidebar] =  useState(true);

  const history = useHistory();
  const dispatch = useDispatch();

  const showSidebar = () => setSidebar(!sidebar);

  function handleLogout() {
    dispatch({ type: 'libras-adm/user/logout' });
    history.push('/');
  }

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items'>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                  </Link>
                </li>
              );
            })}
              <li className='nav-text' style={{cursor: 'pointer'}}>
                  <a onClick={() => handleLogout()}>
                    <FaIcons.FaPowerOff />
                  </a>
              </li>
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
