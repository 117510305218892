import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 53px;
  background-color: var(--secondary);
  border-radius: 10px;
  border-width: 0px;
  outline: none;
  padding: 20px;
`;

export const Text = styled.span`
  font-size: 1.25rem;
  border-style: none;
  color: #fff;
`;

export const IconRight = styled.img`
  position: absolute;
  height: 30px;
  right: 10px;
`;

export const Image = styled.img`
  height: 30px;
`;
