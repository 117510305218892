import * as FaIcons from 'react-icons/fa';


export const SidebarData = [
  {
    path: '/dashboard',
    icon: <FaIcons.FaChartLine />,
    cName: 'nav-text'
  },
  {
    path: '/palavras',
    icon: <FaIcons.FaList />,
    cName: 'nav-text'
  },
  {
    path: '/palavras-nao-encontradas',
    icon: <FaIcons.FaTasks />,
    cName: 'nav-text'
  },
  {
    path: '/config',
    icon: <FaIcons.FaCog />,
    cName: 'nav-text'
  },
];
