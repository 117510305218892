import Navbar from '../../components/VerticalMenu/Navbar';
import Button from '../../components/Button';

import { Container } from './styles';

export const ConfigPag = () => {
  return (
    <>
    <Navbar/>
      <h4 style={{margin: '2% 0 0 10%'}}>Configurações do pacote</h4>
      <Container>
        <div>
          <div>
            <h6>Valor do pacote</h6>
            <input placeholder="R$"/>
          </div>

          <div>
            <h6>Período de renovação</h6>
            <select>
              <option>12 meses</option>
            </select>
          </div>

          <div>
            <h6>Período de renovação</h6>
            <select>
              <option>12 meses</option>
            </select>
          </div>
        </div>
      </Container>
    </>
  );
};
