import Navbar from '../../components/VerticalMenu/Navbar';
import { FaTimes } from 'react-icons/fa';

import { Container } from './styles';

export const NotRegisteredWords = () => {
  return (
    <>
    <Navbar/>
      <h4 style={{margin: '2% 0 0 10%'}}>Palavras não encontradas</h4>
      <Container>
        <div>
          <span/>
          <div>Aprender</div>
          <div>13 jun 2020</div>
          <div className="register">Cadastrar</div>
          <div><FaTimes color='lightgrey'/></div>
        </div>

        <div>
          <span/>
          <div>Aprender</div>
          <div>13 jun 2020</div>
          <div className="register">Cadastrar</div>
          <div><FaTimes color='lightgrey'/></div>
        </div>

        <div>
          <span/>
          <div>Aprender</div>
          <div>13 jun 2020</div>
          <div className="register">Cadastrar</div>
          <div><FaTimes color='lightgrey'/></div>
        </div>

        <div>
          <span/>
          <div>Aprender</div>
          <div>13 jun 2020</div>
          <div className="register">Cadastrar</div>
          <div><FaTimes color='lightgrey'/></div>
        </div>

        <div>
          <span/>
          <div>Aprender</div>
          <div>13 jun 2020</div>
          <div className="register">Cadastrar</div>
          <div><FaTimes color='lightgrey'/></div>
        </div>

        <div>
          <span/>
          <div>Aprender</div>
          <div>13 jun 2020</div>
          <div className="register">Cadastrar</div>
          <div><FaTimes color='lightgrey'/></div>
        </div>

        <div>
          <span/>
          <div>Aprender</div>
          <div>13 jun 2020</div>
          <div className="register">Cadastrar</div>
          <div><FaTimes color='lightgrey'/></div>
        </div>
      </Container>
    </>
  );
};
