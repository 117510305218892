import { createStore } from 'redux';
import Reducer from './reducers';

import { loadState, saveState } from './sessionStorage';

const persistedState = loadState();

const Store = createStore(Reducer, persistedState);

Store.subscribe(() => {
  saveState({
    User: Store.getState().User,
  });
});

export default Store;
