import styled from 'styled-components';

export const ActionBtn = styled.div`
  background: var(--secondary);
  color: #fff;
  padding: 5px;
  width: 210px;
  border-radius: 10px;
  text-align: center;

  &:hover {
    background: #0bd7ef;
  }
`;

export const ActionBtnAdd = styled(ActionBtn)`
  background: orange;
`;

export const ActionBtnEditDel = styled(ActionBtn)`
  padding: 5px;
  width: 122px;
`;
