import styled from 'styled-components';

export const Container = styled.div`
  > div {
    display: flex;
    align-items: center;
    width: 505px;
    margin: 3% 0 0 10%;
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    margin: 3% 0 0 10%;
    width: 75%;
  }

  table .edit {
    cursor: pointer;
  }

  table .edit:hover {
    color: blue;
  }

  table .delete {
    cursor: pointer;
  }

  table .delete:hover {
    color: red;
  }

  td, th {
    border: 1px solid #fff;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #fff;
  }

  .pagination {
    margin: 2% 0px 0px 10%;
  }
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;

.imgRegister {
  border-radius: 10px;
  width: 400px;

> h5 {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

  .imgRegister {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #dedede;
    padding: 65px;
    margin-bottom: 10px;

    > div {
      position: relative;

      > img {
      border-radius: 10px;
    }
  }

label {
  cursor: pointer;
  margin: 0;
  background: #00c5dc;
  position: absolute;
  top: 110px;
  right: -13px;
  cursor: pointer;
  border-radius: 50px;
  padding: 10px 14px 10px 14px;

  &:hover {
    opacity: 0.7;
  }
  input {
    display: none;
  }
}
}

.wordSelect {
  display: flex;
  flex-direction: column;

  > span {
    color: red;
    text-align: center;
    margin: 5px 0 5px 0;
  }
}

.registerBtn {
  font-size: 1.25rem;
  border: 0;
  background: #00c5dc;
  color: #fff;
  padding: 10px 40px 10px 40px;
  border-radius: 10px;
  width: 100%;
  margin-top: 10px;
  }
}

`;
