import React, { InputHTMLAttributes } from 'react';
import { Container, Image, TextInput, IconRight } from './styles';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: string;
  containerStyle?: React.CSSProperties | undefined;
  containerIconStyle?: React.CSSProperties | undefined;
  iconRight?: string;
}

const Input: React.FC<IProps> = ({
  icon,
  containerStyle,
  containerIconStyle,
  iconRight,
  ...rest
}) => {
  function renderIconLeft() {
    if (icon !== undefined) {
      return <Image style={containerIconStyle} src={icon} />;
    }
  }

  function renderIconRight() {
    if (iconRight !== undefined) {
      return <IconRight style={containerIconStyle} src={iconRight} />;
    }
  }

  return (
    <Container style={containerStyle}>
      {renderIconLeft()}
      <TextInput {...rest} />
      {renderIconRight()}
    </Container>
  );
};

export default Input;
