import { useEffect, useState } from 'react';
import Navbar from '../../components/VerticalMenu/Navbar';
import api from '../../services/api';

import { Container } from './styles';

interface UsersDataInterface {
  name: String,
  email: String,
  is_active: Boolean,
  role: String
}

export const Users = () => {
  const [usersData, setUsersData] = useState<UsersDataInterface[]>([]);
  const [loading, setLoading] = useState(false);

  const { User } = JSON.parse(
    sessionStorage.getItem('libra@User') || '{}',
  );

  useEffect(() => {
    async function getUSers() {
      const headers = { Authorization: `Bearer ${User.token}` };
      const response = await api.get('/users', { headers });

      setUsersData(response.data);
      setLoading(true);
    }

    getUSers();
  }, [])

  return (
    <>
    <Navbar/>
      <h4 style={{margin: '2% 0 0 10%'}}>Usuários</h4>
      <Container>
        <table>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Ativo</th>
              <th>Função</th>
            </tr>
            {loading ? (
              usersData.map(user => (
                <tr>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.is_active ? 'Ativado' : 'Desativado'}</td>
                  <td>{user.role}</td>
                </tr>
              ))
          ) : <h5>Carregando...</h5>}
        </table>
      </Container>
    </>
  );
};
