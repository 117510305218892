import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface FormProps {
  hasError: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18vh;
`;

export const Form = styled.form<FormProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 406px;
  height: 503px;
  padding: 63px 36px 48px 32px;
  border-radius: 24px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  background-color: #fff;

  h1 {
    font-size: 2.06rem;
    color: var(--primary);
  }
  p {
    font-size: 1.06rem;
    margin-top: 5.1vh;
    color: #c5c5c5;
  }

  > svg {
    margin-top: 10px;
  }

  > span {
    color: red;
    margin-top: 10px;
    font-weight: 600;
  }

  @media (max-width: 1022px) {
    margin-top: 18vh;
    margin-left: 0px;
  }
  @media (max-width: 32.5rem) {
    text-align: center;
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  margin-top: 2vh;

  > p {
    color: #000000;
    font-size: 1.31rem;
    margin-top: 13px;

    a {
      font-size: 1.31rem;
      color:  var(--primary);
      margin-left: 4px;
    }
  }
`;

export const LinkPage = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
