import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2% 0px 0px 10%;
  opacity: 0.5;
  cursor: no-drop;

  div {
    background: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 6px;
    justify-content: space-around;
    padding: 5px;

    > div {
      margin: 0;
    }

    .register {
      background: #00c5dc;
      color: #fff;
      border-radius: 6px;
      padding: 4px;
    }

    > span {
        border-radius: 50px;
        background: #00c5dc;
        height: 11px;
        width: 11px;
        display: block;
        color: #fff;
        display: flex;
        align-items: center;
        margin-right: 5px;
    }
  }


`;
